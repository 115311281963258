import React from "react";
import { object, func } from "prop-types";
import { StoryElement } from "@quintype/components";

import Close from ".././icons/close";
import "./light-box.m.css";

const LightBox = ({ element, closePopup }) => (
  <div styleName="lightbox-wrapper">
    <div styleName="lightbox" className="lightbox">
      <StoryElement element={element} externalLink={true} imageAspectRatio={[1, 1]} widths={[250, 480, 764]} />
      <button onClick={closePopup} styleName="icon-close">
        <Close color="#1c1c1c" />
      </button>
    </div>
  </div>
);

LightBox.propTypes = {
  element: object,
  closePopup: func
};

export default LightBox;
