import React, { Component } from "react";
import { object, string, bool } from "prop-types";
import { StoryElement, ImageGalleryElement, WithLazy } from "@quintype/components";
import get from "lodash/get";

import LightBox from "../../atoms/lightbox";
import AlsoRead from "../../molecules/also-read";
import GlideSlider from "../../molecules/glide-slider";

import "./story-card.m.css";

class StoryCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      galleryItemIndex: 0
    };

    this.galleryItems = [];
    this.togglePopup = this.togglePopup.bind(this);
  }

  togglePopup(index) {
    this.setState({
      showPopup: !this.state.showPopup,
      galleryItemIndex: index
    });
  }

  getSlideshowElements = elementObj => get(elementObj, ["story-elements"], []);

  render() {
    const templates = {
      "also-read": AlsoRead
    };

    return (
      <div styleName="wrapper">
        {this.props.card["story-elements"].map((element, index) => {
          switch (element.subtype) {
            case "image-gallery":
              if (element.metadata.type === "gallery") {
                return (
                  <div key={index}>
                    {element.type === "composite" ? (
                      <WithLazy margin="50px">
                        {() => (
                          <ImageGalleryElement
                            story={this.props.story}
                            element={element}
                            key={element.id}
                            imageAspectRatio={[1, 1]}
                            widths={[250, 400]}
                            onClickHandler={this.togglePopup}
                          />
                        )}
                      </WithLazy>
                    ) : (
                      <StoryElement
                        renderTemplates={templates}
                        story={this.props.story}
                        element={element}
                        externalLink={true}
                        imageAspectRatio={[1, 1]}
                        imageWidths={[400, 500, 600, 700]}
                      />
                    )}
                    <div>
                      {this.state.showPopup ? (
                        <LightBox
                          element={element["story-elements"][this.state.galleryItemIndex]}
                          closePopup={this.togglePopup}
                        />
                      ) : null}
                    </div>
                  </div>
                );
              } else if (element.metadata.type === "slideshow") {
                const slideShowItems = this.getSlideshowElements(element);

                if (slideShowItems.length) {
                  return (
                    <WithLazy margin="50px" key={index}>
                      {() => (
                        <GlideSlider
                          story={this.props.story}
                          totalItems={slideShowItems.length}
                          slideshowItems={slideShowItems}
                        />
                      )}
                    </WithLazy>
                  );
                }
              }
              break;
            case "also-read":
              return null; // Neglect also-read elements from being dispayed since its displayed in footer
            default:
              // For publication story page showing the attachment is not required
              if (element.subtype === "attachment" && !this.props.showAttachment) {
                return null;
              } else if (element.subtype === "summary" && this.props.isPublicationStory) {
                // Don't display summary element since its handled in publication story page
                return null;
              } else if (this.props.excludeElementId !== element.id) {
                if (element.type === "image") {
                  return (
                    <WithLazy margin="50px" key={index}>
                      {() => (
                        <StoryElement
                          renderTemplates={templates}
                          story={this.props.story}
                          element={element}
                          externalLink={true}
                          imageAspectRatio={[1, 1]}
                          imageWidths={[400, 600, 800]}
                        />
                      )}
                    </WithLazy>
                  );
                }
                return (
                  <div key={index}>
                    <StoryElement
                      renderTemplates={templates}
                      story={this.props.story}
                      element={element}
                      externalLink={true}
                      imageAspectRatio={[1, 1]}
                      imageWidths={[400, 600, 800]}
                    />
                  </div>
                );
              } else {
                return null;
              }
          }
        })}
      </div>
    );
  }
}

StoryCard.defaultProps = {
  showAttachment: true,
  isPublicationStory: true
};

StoryCard.propTypes = {
  card: object,
  story: object,
  excludeElementId: string,
  showAttachment: bool,
  isPublicationStory: bool
};

export default StoryCard;
