import React from "react";
import { array } from "prop-types";

import { sortStories } from "../../helper/utils";

import Headline from "../../atoms/headline";
import CardImageType6 from "../card-image-type-6/index";

import "./also-read.m.css";

const AlsoRead = ({ linkedStories }) => {
  if (!(linkedStories && linkedStories.length)) {
    return null;
  }

  const publicationStories = linkedStories.filter(item => item && item.story["story-template"] === "publication") || [];

  return (
    publicationStories.length > 0 && (
      <div styleName="wrapper">
        <Headline headerType={7} headerLevel={2} text="Publications" />
        <div styleName="content">
          {sortStories(publicationStories).map((item, index) => (
            <CardImageType6 story={item} key={index} />
          ))}
        </div>
      </div>
    )
  );
};

AlsoRead.propTypes = {
  linkedStories: array
};

export default AlsoRead;
