import React from "react";
import { object, array, string, number, bool } from "prop-types";
import get from "lodash/get";
import cx from "classnames";
import { ResponsiveImage, WithLazy } from "@quintype/components";

import { LabelWithBackground } from "../label";

import "./card-image.m.css";

const getSyndicatedFrom = story => get(story, ["metadata", "story-attributes", "syndicatedfrom", "0"], "syndicated");

const CardImage = ({
  story = {},
  aspectRatio,
  defaultWidth,
  showAlternateImage,
  imageType = "image16x9",
  widths = [250, 480, 764],
  isIcon = true
}) => {
  const wrapperStyleName = cx(
    "qt-image-dynamic",
    { image16x9: imageType === "image16x9" },
    { image2x1: imageType === "image2x1" },
    { image4x3: imageType === "image4x3" },
    { image1x1: imageType === "image1x1" },
    { image267x332: imageType === "image267x332" },
    { image1x2: imageType === "image1x2" },
    { image9x16: imageType === "image9x16" },
    { image8x55: imageType === "image8x55" }
  );

  let heroImage = "";

  if (showAlternateImage && get(story, ["alternative", "home", "default", "hero-image", "hero-image-s3-key"])) {
    heroImage = story.alternative.home.default["hero-image"]["hero-image-s3-key"];
  } else if (story["hero-image-s3-key"]) {
    heroImage = story["hero-image-s3-key"];
  }

  return (
    <WithLazy margin="50px">
      {() => (
        <figure styleName={wrapperStyleName} className={wrapperStyleName}>
          {/* Desktop WebP */}
          <ResponsiveImage
            slug={heroImage}
            metadata={story["hero-image-metadata"]}
            aspectRatio={aspectRatio}
            defaultWidth={defaultWidth}
            widths={widths}
            styleName="image"
            className="image"
            alt={story["hero-image-caption"] || story.headline}
          />
          {/* Desktop Jpeg */}
          {story["story-template"] === "syndicated" && (
            <div styleName="syndicated">
              <LabelWithBackground text={getSyndicatedFrom(story)} />
            </div>
          )}
          {story["story-template"] === "video" &&
            isIcon && (
              <div styleName="video-wrapper">
                <div className="video-icon" />
              </div>
            )}
        </figure>
      )}
    </WithLazy>
  );
};

CardImage.defaultProps = {
  showAlternateImage: false
};

CardImage.propTypes = {
  story: object,
  aspectRatio: array,
  defaultWidth: number,
  imageType: string,
  widths: array,
  isIcon: bool,
  showAlternateImage: bool
};

export default CardImage;
