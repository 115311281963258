import React, { Component } from "react";
import { object, array, number } from "prop-types";
import { StoryElement } from "@quintype/components";
import Arrow1 from "../../atoms/icons/arrow-1";
import Arrow2 from "../../atoms/icons/arrow-2";
import LightBox from "../../atoms/lightbox";
import Carousel from "./glide";

import "./slider.m.css";

class GlideSlider extends Component {
  state = {
    carouselIndex: 1,
    showPopup: false
  };

  togglePopup = () => {
    this.setState({
      showPopup: !this.state.showPopup
    });
  };

  next = () => {
    this.state.carouselIndex !== this.props.totalItems
      ? this.setState({ carouselIndex: this.state.carouselIndex + 1 })
      : this.setState({ carouselIndex: 1 });
  };

  previous = () => {
    this.state.carouselIndex === 1
      ? this.setState({ carouselIndex: this.props.totalItems })
      : this.setState({ carouselIndex: this.state.carouselIndex - 1 });
  };

  render() {
    const { story, slideshowItems, totalItems } = this.props;
    const { carouselIndex, showPopup } = this.state;

    return (
      <div styleName="carousel-container">
        <Carousel
          carouselName={`carousel-${story.id}`}
          options={{
            type: "carousel",
            perView: 1
          }}
          totalItems={totalItems}
          renderCenterLeftControls={({ previousSlide }) => (
            <button onClick={this.previous} className="slider-btn">
              <Arrow2 />
            </button>
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <button onClick={this.next} className="slider-btn">
              <Arrow1 />
            </button>
          )}
        >
          {[
            <div styleName="image-element" onClick={this.togglePopup} key={slideshowItems[carouselIndex - 1].id}>
              <StoryElement
                element={slideshowItems[carouselIndex - 1]}
                story={story}
                key={slideshowItems[carouselIndex - 1].id}
                externalLink={true}
                imageAspectRatio={[1, 1]}
                imageWidths={[400, 500, 600, 700]}
              />
            </div>
          ]}
        </Carousel>
        {showPopup && <LightBox element={slideshowItems[carouselIndex - 1]} closePopup={this.togglePopup} />}
      </div>
    );
  }
}

GlideSlider.propTypes = {
  totalItems: number,
  slideshowItems: array,
  story: object
};

export default GlideSlider;
