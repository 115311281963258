import React from "react";
import { shape, string } from "prop-types";

import Author from "../icons/author";
import CardImage from "../card-image";
import "./author.m.css";

const AuthorAvatar = ({ author }) => {
  const avatarURL = author["avatar-s3-key"];
  const authorAvatarUrl = author["avatar-url"];
  const name = author.name;

  const storyObj = {
    "hero-image-s3-key": avatarURL,
    "hero-image-metadata": "",
    "hero-image-caption": "Author",
    alt: name
  };

  let imageView = null;

  if (avatarURL) {
    imageView = (
      <CardImage story={storyObj} defaultWidth={240} aspectRatio={[1, 1]} imageType="image1x1" widths={[240, 120]} />
    );
  } else if (authorAvatarUrl) {
    imageView = <img src={authorAvatarUrl} alt={author.name} />;
  } else {
    imageView = <Author width={120} height={120} />;
  }

  return (
    <div styleName="author-avatar" className="author-avatar">
      {imageView}
    </div>
  );
};

AuthorAvatar.propTypes = {
  author: shape({
    avatarURL: string,
    "avatar-url": string
  })
};

export default AuthorAvatar;
