import React from "react";
import { object, array, string, number } from "prop-types";
import cx from "classnames";
import { ResponsiveImage, WithLazy } from "@quintype/components";

import { getEntityColorCode } from "../../helper/utils";

import "./publication-entity-image.m.css";

const PublicationEntityImage = ({
  entity = {},
  aspectRatio,
  defaultWidth,
  imageType = "image16x9",
  widths = [250, 480, 764]
}) => {
  const wrapperStyleName = cx(
    "qt-image-dynamic",
    { image16x9: imageType === "image16x9" },
    { image2x1: imageType === "image2x1" },
    { image4x3: imageType === "image4x3" },
    { image1x1: imageType === "image1x1" },
    { image267x332: imageType === "image267x332" },
    { image1x2: imageType === "image1x2" }
  );
  const { photo, name } = entity;

  // Atleast 1 photo object should be available
  if (!(photo && photo.length)) {
    return null;
  }

  // Get the first object from photo list
  const photoObj = photo[0];

  // If photo object available check for key and metadata
  if (!(photoObj.key && photoObj.metadata)) {
    return null;
  }

  const colorCode = getEntityColorCode(name);

  const imageView = () => (
    <WithLazy margin="50px">
      {() => (
        <figure styleName={wrapperStyleName} className={wrapperStyleName}>
          <ResponsiveImage
            slug={photo[0]["key"]}
            metadata={photo[0]["metadata"]}
            aspectRatio={aspectRatio}
            defaultWidth={400}
            widths={widths}
            styleName="image"
            className="image"
            alt={photo[0]["caption"] || "NCF"}
          />
        </figure>
      )}
    </WithLazy>
  );

  // Handle if its an svg file
  if (photoObj.metadata["mime-type"] === "image/svg+xml") {
    return (
      <div style={{ backgroundColor: colorCode }} className="entity-svg-view">
        <div styleName="svg-wrapper">
          <ResponsiveImage slug={photoObj["key"]} metadata={photoObj["metadata"]} alt={photoObj["caption"] || "NCF"} />
        </div>
      </div>
    );
  }

  return imageView;
};

PublicationEntityImage.propTypes = {
  entity: object,
  aspectRatio: array,
  defaultWidth: number,
  imageType: string,
  widths: array
};

export default PublicationEntityImage;
