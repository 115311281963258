import React from "react";

const Placeholder = () => (
  <svg viewBox="0 0 110 80">
    <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect fill="#FFFFFF" x="0" y="0" width="110" height="80" />
      <g id="feed-story-placeholder" fillRule="nonzero">
        <rect id="Rectangle" fill="#A2A2A2" opacity="0.247" x="0" y="0" width="110" height="80" />
        <g id="Group" opacity="0.85" transform="translate(42.000000, 30.000000)" fill="#FFFFFF">
          <path
            d="M25.5540506,0 L0.411392405,0 C0.184221519,0 0,0.17904 0,0.4 L0,19.51344 C0,19.7344 0.184221519,19.91344 0.411392405,19.91344 L25.5540506,19.91344 C25.7813861,19.91344 25.965443,19.7344 25.965443,19.51344 L25.965443,0.4 C25.965443,0.17904 25.7813861,0 25.5540506,0 Z M23.4970886,16.7244 L17.8391266,11.02728 C17.7765949,10.96448 17.6743228,10.9604 17.6068544,11.01816 L13.6825823,14.37648 L8.66672152,8.3716 C8.63413924,8.3324 8.5865,8.31 8.53260762,8.31112 C8.48068991,8.31216 8.43222788,8.3364 8.40120889,8.37688 L2.46835443,16.11728 L2.46835443,2.4 L23.4970886,2.4 L23.4970886,16.7244 Z"
            id="Shape"
          />
          <path
            d="M16.9860381,7.49011429 C18.0999242,7.49011429 19.004247,6.61155429 19.004247,5.52763429 C19.004247,4.44403429 18.0999242,3.56499429 16.9860381,3.56499429 C15.871247,3.56499429 14.9679116,4.44403429 14.9679116,5.52763429 C14.9679116,6.61147429 15.8713293,7.49011429 16.9860381,7.49011429 Z"
            id="Path"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Placeholder;
