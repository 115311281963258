import React from "react";
import { object, bool, func, array } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import flatten from "lodash/flatten";
import { Link, ResponsiveImage } from "@quintype/components";

import Headline from "../../atoms/headline";
import PdfIcon from "../../atoms/icons/pdf-icon";
import PublicationEntityImage from "../../atoms/publication-entity-image";
import { LabelWithBackground } from "../../atoms/label";

import { formatBytes, filterAlumnusAuthors } from "../../helper/utils";

import "./card-image-type-6.m.css";

const CardImageType6 = ({ story, isUserLoggedIn, navigateToPage, entityList, authorList }) => {
  const storyObj = story.story ? story.story : story;
  const isLoginAccess = storyObj.access === "login";
  const publicationYearObj = get(storyObj, ["metadata", "story-attributes", "publicationyear", "0"], null);
  const publicationTypeObj = get(storyObj, ["metadata", "story-attributes", "publicationtype", "0"], null);

  const elementAttachmentList =
    storyObj.cards &&
    storyObj.cards.length &&
    storyObj.cards.map(card => card["story-elements"].filter(storyElement => storyElement.subtype === "attachment"));

  const tagList = [];
  let attachmentObj = null;

  if (elementAttachmentList && elementAttachmentList.length) {
    const attachmentList = flatten(elementAttachmentList);

    if (attachmentList && attachmentList.length) {
      attachmentObj = attachmentList[0];
      const size = get(attachmentObj, ["metadata", "file-size"], 0);
      // Calculate the size in human readable format
      attachmentObj.fileSize = size ? formatBytes(size) : size;
    }
  }

  // 1st tag should be publication type
  if (publicationTypeObj && publicationTypeObj.type === "publication-type") {
    tagList.push({ id: publicationTypeObj.id, name: publicationTypeObj.name });
  } else if (publicationTypeObj && publicationTypeObj.type === "Publication Type") {
    tagList.push({ id: publicationTypeObj.id, name: publicationTypeObj.name });
  }

  // 2nd tag should be publication year
  if (publicationYearObj && publicationYearObj.type === "publication-year") {
    tagList.push({ id: publicationYearObj.id, name: publicationYearObj.name });
  } else if (publicationYearObj && publicationYearObj.type === "Publication Year") {
    tagList.push({ id: publicationYearObj.id, name: publicationYearObj.name });
  }

  const tags = tagList.map(tag => <LabelWithBackground key={tag.id} text={tag.name} />);

  // Check if authors exist and has name property in it
  const authorView = () => {
    const authors = filterAlumnusAuthors(authorList, storyObj.authors || [], true);

    return authors.filter(a => a.name).map((author, index) => (
      <Link key={index} href={`/author/${author.id}/${author.slug}`} styleName="links">
        <span>{author.name}</span>
      </Link>
    ));
  };

  // const downloadClick = () => {
  //   if (isUserLoggedIn) {
  //     window.open(attachmentObj.url, "_blank");
  //   } else {
  //     navigateToPage("/signin");
  //   }
  // };

  const detailsDownloadView = (
    <div styleName="details-downloads">
      <Link href={`/${storyObj.slug}`}>
        <span styleName="details">Details</span>
      </Link>
      {attachmentObj ? (
        isLoginAccess ? (
        // <div styleName="details">Not avaiable for download</div>
        null
        ) : (
          <a href={attachmentObj.url} target="_blank" rel="noopener noreferrer" styleName="attachment-link">
            <PdfIcon />
            <span styleName="details">Download {attachmentObj.fileSize ? `(${attachmentObj.fileSize})` : ""}</span>
          </a>
        )
      ) : (
        <div styleName="empty" />
      )}
    </div>
  );

  const publicationTypeEntity = publicationTypeObj
    ? entityList && entityList.find(c => c.id === publicationTypeObj.id)
    : {};

  return (
    <div styleName={` wrapper gridView `}>
      {
        <div styleName="image-wrapper">
          {storyObj["hero-image-s3-key"] ? (
            <ResponsiveImage
              slug={storyObj["hero-image-s3-key"]}
              metadata={storyObj["hero-image-metadata"]}
              aspectRatio={[1, 2]}
              defaultWidth={300}
              widths={[300]}
              type="image/webp"
              sizes="( max-width: 120px ) 98%, ( max-width: 768px ) 48%, 23%"
              imgParams={{ auto: ["format", "compress"], fmt: "webp" }}
              styleName="inner-image"
              alt={storyObj["hero-image-caption"] || storyObj.headline}
            />
          ) : (
            <PublicationEntityImage
              entity={publicationTypeEntity}
              defaultWidth={36}
              widths={[300]}
              aspectRatio={[1, 2]}
              imageType="image1x2"
            />
          )}
        </div>
      }
      <div styleName="card-details">
        {tags.length ? <div styleName="tags">{tags}</div> : null}
        <Headline text={storyObj.headline} headerType={2} headerLevel={3} />
        <div styleName="authors">{authorView()}</div>
        <div styleName="links-desktop">{detailsDownloadView}</div>
      </div>
      <div styleName="links-mobile">{detailsDownloadView}</div>
    </div>
  );
};

CardImageType6.propTypes = {
  story: object,
  isUserLoggedIn: bool,
  navigateToPage: func,
  entityList: array,
  authorList: array
};

const mapStateToProps = state => {
  return {
    isUserLoggedIn: get(state, ["authReducer", "isUserLoggedIn"], false),
    entityList: get(state, ["entityReducer"], []),
    authorList: get(state, ["peopleReducer"], [])
  };
};

const mapDispatchToProps = dispatch => ({
  navigateToPage: url => global.app.navigateToPage(dispatch, url)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardImageType6);
