import React from "react";
import { array, string, bool } from "prop-types";
import { connect } from "react-redux";
import sortBy from "lodash/sortBy";
import get from "lodash/get";

import Headline from "../../atoms/headline";
import AuthorDetails from "../../molecules/author-details";

import { filterAlumnusAuthors, getFormattedAuthors } from "../../helper/utils";

import "./people-team.m.css";

const PeopleTeamRow = ({ authors = [], authorList, headerText = "People", showAlumnus = false, isGropuBy = false }) => {
  const newAuthors = filterAlumnusAuthors(authorList, authors, showAlumnus);
  const sortedAuthorsList = sortBy(newAuthors, [e => e.name]);

  if (!(sortedAuthorsList && sortedAuthorsList.length)) {
    return null;
  }

  const getView = (list, heading) => (
    <div className="container">
      <div styleName="header-wrapper">
        <Headline headerType={7} headerLevel={2} text={heading} />
      </div>
      <div styleName="content">
        {list.map((author, index) => (
          <AuthorDetails key={index} author={author} isDesignation={true} />
        ))}
      </div>
    </div>
  );

  // Create a list view when isGropuBy = true
  const getPeopleView = () => {
    const authorList = getFormattedAuthors(sortedAuthorsList);
    if (!(authorList && authorList.length)) return null;

    return authorList.map((item, index) => (
      <React.Fragment key={index}>{getView(item.list, item.heading)}</React.Fragment>
    ));
  };

  if (!isGropuBy) return getView(sortedAuthorsList, headerText);

  return getPeopleView();
};

PeopleTeamRow.propTypes = {
  authors: array,
  authorList: array,
  headerText: string,
  showAlumnus: bool,
  isGropuBy: bool
};

const mapStateToProps = state => {
  return {
    authorList: get(state, ["peopleReducer"], [])
  };
};

export default connect(
  mapStateToProps,
  null
)(PeopleTeamRow);
