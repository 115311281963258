import React from "react";
import { string, number } from "prop-types";
import { Link } from "@quintype/components";
import { getTime } from "../../helper/utils";

import "./by-name.m.css";

const ByName = ({ name, date, id, authorSlug }) => {
  return (
    <div styleName="wrapper" className="author-name">
      <Link href={`/author/${id}/${authorSlug}`}>
        <span>By &nbsp;</span>
        {name}{" "}
      </Link>{" "}
      {date && <span styleName="time-ago">{getTime(date)}</span>}
    </div>
  );
};

ByName.propTypes = {
  name: string,
  date: number,
  id: string,
  authorSlug: string
};

export default ByName;
