import React from "react";
import { object, bool } from "prop-types";
import get from "lodash/get";
import { Link } from "@quintype/components";

import Headline from "../../atoms/headline";
import Author from "../../atoms/author";

import "./author-details.m.css";

const AuthorDetails = ({ author = {}, isDesignation }) => {
  let title = null;

  if (isDesignation) {
    title = get(author, ["metadata", "Designation"], "");
  } else {
    title = get(author, ["metadata", "Home Programme"], "");
  }

  return (
    <Link href={`/author/${author.id}/${author.slug}`} styleName="wrapper" className="author-details">
      <Author author={author} />
      <Headline text={author.name} headerType={2} headerLevel={2} />
      {title && <p styleName="author-bio">{title}</p>}
      {author.isAlumnus ? <p styleName="alumnus">Alumnus</p> : null}
    </Link>
  );
};

export default AuthorDetails;

AuthorDetails.propTypes = {
  author: object,
  isDesignation: bool
};
