import React from "react";
import "./label.m.css";
import { string } from "prop-types";

const Label = ({ text }) => (
  <h4 styleName="label-wrapper" className="label-wrapper">
    {text}
  </h4>
);

const LabelWithBackground = ({ text }) => (
  <h4 styleName="label-wrapper-with-background" className="label-wrapper-with-background">
    {text}
  </h4>
);

export { LabelWithBackground, Label };

Label.propTypes = {
  text: string
};

LabelWithBackground.propTypes = {
  text: string
};
